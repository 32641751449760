/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Backdrop from '@mui/material/Backdrop';
import { MuiLoader } from '../Loader';
export var MuiBackdrop = function (_a) {
    var open = _a.open, handleClose = _a.handleClose;
    return (_jsx(Backdrop, __assign({ sx: { color: '#fff', zIndex: function (theme) { return theme.zIndex.drawer + 1; } }, open: open, onClick: handleClose }, { children: _jsx(MuiLoader, {}) })));
};

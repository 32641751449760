import axios from 'axios'

// GET
export const GetPartners = (name, cnpj, page) =>
    axios
        .get(`/Partner?name=${name}&cpnj=${cnpj}&page=${page}`)
        .then((response) => response.data)

// GET
export const GetPartnerByConsumer = () =>
    axios
        .get(`/ConsumerWallet/GetPartnerByConsumer`)
        .then((response) => response)
        .catch((error) => error?.response)

export const PartnerValidatePartner = ({ password }) => {
    const obj = {
        password,
    }
    return axios
        .post(`/Partner/ValidatePartner`, obj)
        .then((response) => response.data)
}

export const GetPartnerByFilter = ({
    name,
    type,
    status,
    contactName,
    page = 1,
    pageSize = 10,
}) => {
    let url = `/Partner`

    if (name && type === 'name') {
        url += `&Name=${name}`
    }

    if (name && type === 'CNPJ') {
        url += `&CNPJ=${name?.replace(/\D/g, '')}`
    }
    if (status) {
        url += `&status=${status}`
    }

    if (contactName) {
        url += `&contactName=${contactName}`
    }

    if (page && pageSize) {
        url += `&page=${page}&pageSize=${pageSize}`
    }

    if (url.indexOf('&') !== -1) url = url.replace('&', '?')
    return axios.get(url).then((response) => response.data)
}

import axios from 'axios'

// UserPreference
export const GetUserPreference = () =>
    axios.get(`/UserPreference`).then((response) => response.data)

// GET api/ProgramRules/General
export const PutUserPreference = (viewImportantAlerts) =>
    axios
        .put(`/UserPreference?viewImportantAlerts=${viewImportantAlerts}`)
        .then((response) => response?.data)

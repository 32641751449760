import React, { Component } from 'react'
import { MuiBackdrop, MuiBox } from '../../components/storybook'
import ShopHistoryDetail from '../ShopHistory/ShopHistoryDetail.jsx'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { GetInvoiceById } from 'redux/actions'

class PayrollDebitDetailsChild extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoadingData: false,
            open: false,
        }
    }

    componentDidMount() {
        this.fetchPayrollDebitData()
    }

    fetchPayrollDebitData = async () => {
        const { id } = this.props.match.params
        this.setState({ isLoadingData: true, open: true })

        try {
            await this.props.GetInvoiceById(id)
            this.setState({
                isLoadingData: false,
            })
        } catch (error) {
            this.setState({ isLoadingData: false })
        }
    }
    handleGoBack = () => {
        this.props.history.goBack()
    }

    render() {
        const { isLoadingData, open } = this.state

        return (
            <MuiBox>
                {isLoadingData && (
                    <MuiBackdrop open={isLoadingData} handleClose={() => {}} />
                )}

                <ShopHistoryDetail
                    handleGoBack={this.handleGoBack}
                    isLoadingData={this.state.isLoadingData}
                    open={open}
                />
            </MuiBox>
        )
    }
}

export default connect(null, { GetInvoiceById })(
    withRouter(PayrollDebitDetailsChild),
)

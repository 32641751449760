import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PayrollDebitService from '../Services/payrollDebit'
import { GetPartners } from '../../../redux/api/partner'

export default function usePayrollDebit() {
    const { t } = useTranslation()

    const payrollDebitService = new PayrollDebitService()
    const [isLoadingData, setIsLoadingData] = useState(false)

    const [payrollDebitData, setPayrollDebitData] = useState([])
    const [partners, setPartners] = useState([])
    const [payrollDebitModalOpen, setPayrollDebitModalOpen] = useState(false)
    const [cancelPayrollDebitModalOpen, setCancelPayrollDebitModalOpen] =
        useState(false)
    const [isFilterData, setIsFilterData] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [cancelItem, setCancelItem] = useState(null)
    const [tablePagination, setTablePagination] = useState({
        page: 1,
        pageSize: 5,
        totalPages: 1,
    })
    const [paginationPayrollDebit, setPaginationPayrollDebit] = useState({
        page: 1,
        pageSize: 5,
        totalPages: 1,
    })
    const [notificationMessage, setNotificationMessage] = useState({
        open: false,
        message: 'E-mail enviado com sucesso!',
        type: 'success',
    })

    const fetchPartners = async (searchTerm) => {
        const response = await GetPartners(searchTerm, '', 1)
        if (response && response.results) {
            const formattedPartners = response.results.map((partner) => ({
                value: partner.id,
                label:
                    partner.name + (partner.cnpj ? ` | ${partner.cnpj}` : ''),
            }))
            setPartners(formattedPartners)
        }
    }
    function changeNotificationMessage(key, value) {
        setNotificationMessage((prev) => {
            return {
                ...prev,
                [key]: value,
            }
        })
    }

    function convertOptions(data) {
        let result = []

        data.forEach((item) => {
            result.push({
                key: item.key,
                value: item.value,
                label: t(`NEGOTIATION.${item.value}`),
            })
        })

        return result
    }

    const getPayrollDebitData = useCallback(async () => {
        setIsLoadingData(true)

        const response = await payrollDebitService.getPayrollDebitHistory()

        if (response) {
            setPayrollDebitData(response)
            setTablePagination({
                page: response?.currentPage,
                pageSize: response?.pageSize,
                totalPages: response?.pageCount,
            })
            setPaginationPayrollDebit({
                page: response?.currentPage,
                pageSize: response?.pageSize,
                totalPages: response?.pageCount,
            })
        }
        setIsLoadingData(false)
    }, [])

    const getFilteredDebit = useCallback(async (id) => {
        setIsLoadingData(true)
        const response = await payrollDebitService.getFilteredDebit(id)

        setPayrollDebitData(response)
        if (response?.results?.length < 1) {
            setIsFilterData(true)
        }

        setIsLoadingData(false)
    }, [])

    const getPaginatedPayrollDebit = useCallback(
        async (key, value, tablePagination) => {
            setIsLoadingData(true)

            let filter = {
                ...tablePagination,
            }
            if (key === 'page') {
                filter.page = value
            } else {
                filter.pageSize = value
                filter.page = 1
            }

            const response = await payrollDebitService.getPaginationDebit({
                page: filter.page,
                pageSize: filter.pageSize,
            })

            if (response) {
                setPayrollDebitData(response)
                setTablePagination({
                    page: response?.currentPage,
                    pageSize: response?.pageSize,
                    totalPages: response?.pageCount,
                })
            }
            setIsLoadingData(false)
        },
        [],
    )
    function handleCloseStack() {
        changeNotificationMessage('open', false)
    }

    return {
        getPayrollDebitData,
        getFilteredDebit,
        isLoadingData,
        setIsLoadingData,
        payrollDebitData,
        setPayrollDebitData,
        tablePagination,
        paginationPayrollDebit,
        getPaginatedPayrollDebit,
        payrollDebitModalOpen,
        setPayrollDebitModalOpen,
        cancelPayrollDebitModalOpen,
        setCancelPayrollDebitModalOpen,
        isFilterData,
        convertOptions,
        selectedItem,
        setSelectedItem,
        notificationMessage,
        changeNotificationMessage,
        handleCloseStack,
        cancelItem,
        setCancelItem,
        setTablePagination,
        fetchPartners,
        partners,
    }
}

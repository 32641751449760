import React, { Fragment } from 'react'
import * as Yup from 'yup'
import * as _ from 'lodash'
import * as moment from 'moment'
import Alert from 'react-bootstrap-sweetalert'
import { Formik } from 'formik'
import { withTranslation } from 'react-i18next'
import Axios from 'axios'
//Core components

import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
// imgs
// UTILS
import compose from 'utils/compose'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './resetPassword.module.scss'
// REDIX INIT
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    GetResetPasswordToken,
    GetCurrentConsumer,
    //GetConsumerByToken,
    PostResetPassword,
    SweetAlert,
    HideAlert,
    ShowLoader,
    HideLoader,
} from 'redux/actions'
import * as API from '../../../redux/api/user'

import ResetPasswordForm from './Component/ResetPassword'
import RequestPasswordResetForm from './Component/RequestPasswordReset'
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import { connectRouterState } from 'utils/connectRouterState'
import SnackDefault from 'components/SnackDefault'
import { GetThemeColorFromStorage } from '../../../redux/api/themeColor'
import { GetConsumerByToken } from '../../../redux/api/user'
import { formatCellAndTell } from '../../../utils/utils'
import * as S from './style'
import { envs } from '../../../redux/api/windowServerData'

const germiniLogoInterna =
    GetThemeColorFromStorage()?.images?.internalPages?.menuLogo?.url

const requestValidationSchema = Yup.object({
    login: Yup.string().required('E-mail ou CPF é obrigatório.'),
})

const resetValidationSchema = Yup.object({
    token: Yup.string(),
    userObject: Yup.object(),
    newPassword: Yup.string()
        .test(
            'string compare',
            'A senha não pode conter CPF, CNPJ, Data de nascimento, telefone ou celular.',
            function (newPassword) {
                const { user } = this.parent

                if (newPassword && user) {
                    const { cpf, dateOfBirth, phoneNumber2, email } = user
                    if (cpf) {
                        const cpfFormatted = cpf
                            ?.replace(/\D/g, '')
                            ?.replace(
                                /(\d{3})(\d{3})(\d{3})(\d{2})/g,
                                '$1.$2.$3-$4',
                            )

                        if (
                            newPassword?.includes(cpf) ||
                            newPassword?.includes(cpfFormatted)
                        ) {
                            return false
                        }
                    }
                    if (dateOfBirth) {
                        if (
                            newPassword?.includes(
                                moment(dateOfBirth)
                                    .format('DD/MM/YYYY')
                                    ?.replaceAll('/', ''),
                            )
                        ) {
                            return false
                        }
                        if (
                            newPassword?.includes(
                                moment(dateOfBirth).format('DD/MM/YYYY'),
                            )
                        ) {
                            return false
                        }
                        if (
                            newPassword?.includes(
                                moment(dateOfBirth).format('DD/MM/YY'),
                            )
                        ) {
                            return false
                        }

                        if (
                            newPassword?.includes(
                                moment(dateOfBirth).format('DD.MM.YYYY'),
                            )
                        ) {
                            return false
                        }
                        if (
                            newPassword?.includes(
                                moment(dateOfBirth).format('DD.MM.YY'),
                            )
                        ) {
                            return false
                        }
                        if (
                            newPassword?.includes(
                                moment(dateOfBirth).format('YYYY/MM/DD'),
                            )
                        ) {
                            return false
                        }

                        if (
                            newPassword?.includes(
                                moment(dateOfBirth).format('MM/DD/YYYY'),
                            )
                        ) {
                            return false
                        }
                        if (
                            newPassword?.includes(
                                moment(dateOfBirth).format('MM/DD/YY'),
                            )
                        ) {
                            return false
                        }
                    }
                    if (phoneNumber2) {
                        const passWard = newPassword.replace(' ', '') || ''

                        if (
                            passWard.includes(
                                formatCellAndTell(phoneNumber2)?.replace(
                                    ' ',
                                    '',
                                ),
                            )
                        ) {
                            return false
                        }
                    }
                    if (email) {
                        if (newPassword.includes(email)) {
                            return false
                        }
                    }
                }
                return true
            },
        )
        .test(
            'string compare',
            'Não deve conter números repetidos em ordem',
            function (newPassword = '') {
                for (let i = 0; i <= 9; i++) {
                    if (newPassword.includes(`${i}${i}${i}`)) {
                        return false
                    }
                }
                return true
            },
        )
        .test(
            'string compare',
            'Senha não pode ter mais de 3 números sequenciais',
            (newPassword = '') => {
                var re =
                    /(?:(?=0[1-2-3]|1[2-3-4]|2[3-4-5]|3[4-5-6]|4[5-6-7]|5[6-7-8]|6[7-8-9])\d){3}/
                if (
                    re.test(newPassword) ||
                    newPassword.includes('5678') ||
                    newPassword.includes('6789')
                ) {
                    return false
                }
                return true
            },
        )
        .test(
            'string compare',
            'Deve conter letras, números e caracteres especiais',
            function (newPassword = '') {
                if (newPassword) {
                    const numberMatches = newPassword.match(/\d+/g) //eslint-disable-line
                    const specialCharacters =
                        /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/ //eslint-disable-line
                    const specialCharactersMatches =
                        specialCharacters.test(newPassword) //eslint-disable-line

                    if (!numberMatches || !specialCharactersMatches)
                        return false
                }
                return true
            },
        )
        .test(
            'string compare',
            'Proibido mais de 3 números repetidos em ordem',
            function (newPassword = '') {
                for (let i = 0; i <= 9; i++) {
                    if (newPassword.includes(`${i}${i}${i}`)) {
                        return false
                    }
                }
                return true
            },
        )
        .min(8, 'Mínimo de 8 caracteres')
        .max(20, 'Máximo de 20 caracteres')
        .required('Senha nova é obrigatório'),
    confirmPassword: Yup.string()
        .min(8, 'mínimo de 8 caracteres')
        .max(20, 'máximo de 20 caracteres')
        .oneOf(
            [Yup.ref('newPassword'), null],
            'A confirmação de senha não confere.',
        )
        .required('Confirme a nova senha'),
})

class ResetPassword extends React.Component {
    constructor(props) {
        super(props)
        this.formik = React.createRef()
        this.state = {
            id: '',
            token: '',
            login: '',
            newPassword: '',
            confirmPassword: '',
            alert: null,
            cpf: '',
            snackStatus: {
                open: false,
                severity: 'success',
                text: '',
            },
        }
        if (localStorage.getItem('i18nextLng') !== null) {
            moment.locale(localStorage.getItem('i18nextLng'))
        } else {
            moment.locale('pt-BR')
        }
    }

    componentDidMount() {
        this.setState({ ...this.props.urlState })
        if (this.props.urlState.token)
            this.getUserByToken(this.props.urlState.token)
    }

    async getUserByToken(token) {
        const newToken = token
        const user = await GetConsumerByToken(token)

        if (user?.status === 200 && user?.statusText === 'OK') {
            const { data } = user
            this.formik.current.setFieldValue('user', data?.data)
        }
    }

    handleSendSMS() {
        let value = this.state.cpf
        let cpf = value.replace(/(\.|\/|\-)/g, '')
        this.hideAlert()

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json-patch+json',
                appId: 'CSM1803',
                // companyInternalName: 'germini',
            },
            Accept: 'application/json',
            body: `{}`,
        }

        return fetch(
            // `${envs.REACT_APP_GERMINI_API_URL}/Consumer/SendSmsActivationCode?cpfCnpj=${cpf}`,
            `${envs.REACT_APP_GERMINI_API_URL}/Consumer/SendSmsActivationCode?cpfCnpj=${cpf}`,
            requestOptions,
        ).then((response) => {
            if (response.status === 200) {
                return response.text().then((text) => {
                    const data = text && JSON.parse(text)
                    if (data.success) {
                        this.setState({
                            snackStatus: {
                                open: true,
                                severity: 'success',
                                text: 'SMS enviado com sucesso!',
                            },
                        })
                        localStorage.setItem(
                            'consumerSMS',
                            JSON.stringify({
                                consumer: data.data,
                                expireDate: new Date().getTime() + 900000,
                            }),
                        )
                        this.props.history.push('/ConfirmRegistration')
                    } else
                        this.setState({
                            alert: (
                                <Alert
                                    warning
                                    style={{
                                        display: 'block',
                                        marginTop: '-100px',
                                    }}
                                    title={null}
                                    onConfirm={() => {
                                        this.setState({ alert: '' })
                                    }}
                                    confirmBtnCssClass={
                                        this.props.classes.button +
                                        ' ' +
                                        this.props.classes.greenButtonColor
                                    }
                                    confirmBtnText={'Continuar'}
                                >
                                    {_.isArray(data.errors) ? (
                                        data.errors.map((error) => [
                                            <div
                                                style={{
                                                    fontWeight: 500,
                                                    fontFamily: 'helvetica',
                                                }}
                                            >
                                                {this.props.t(error.message)}
                                            </div>,
                                        ])
                                    ) : (
                                        <div
                                            style={{
                                                fontWeight: 500,
                                                fontFamily: 'helvetica',
                                            }}
                                        >
                                            {
                                                'Ocorreu um erro ao realizar a operação!'
                                            }
                                        </div>
                                    )}
                                </Alert>
                            ),
                        })
                    return data
                })
            } else {
                return response.text().then((text) => {
                    const error = text && JSON.parse(text)
                    console.log('Falha: ', error)
                    return Promise.reject(error)
                })
            }
        })
    }

    handleSendEmail = async (cpf) => {
        const response = await Axios(
            // `${envs.REACT_APP_GERMINI_API_URL}/Consumer/SendEmailConfirmationToken?cpfCnpj=${cpf}`,
            `${envs.REACT_APP_GERMINI_API_URL}/Consumer/SendEmailConfirmationToken?cpfCnpj=${cpf}`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    appId: 'CSM1803',
                    // companyInternalName: 'germini',
                },
                Accept: 'application/json',
                body: `{}`,
            },
        )

        const { status, data } = response

        if (status === 200) {
            this.hideAlert()
            const { errors } = data

            if (errors?.length > 0) {
                this.setState({
                    snackStatus: {
                        open: true,
                        text: this.props.t(errors[0].message),
                        severity: 'error',
                    },
                })
                return
            }

            this.setState({
                snackStatus: {
                    open: true,
                    text: 'E-mail enviado com sucesso!',
                    severity: 'success',
                },
            })
        }
    }
    // handleSendEmail = (cpf) => {
    //     const requestOptions = {
    //         method: 'PUT',
    //         headers: {
    //             'Content-Type': 'application/json-patch+json',
    //             appId: 'CSM1803',
    //         },
    //         Accept: 'application/json',
    //         body: `{}`,
    //     }

    //     return fetch(
    //         `${envs.REACT_APP_GERMINI_API_URL}/Consumer/SendEmailConfirmationToken?cpfCnpj=${cpf}`,
    //         requestOptions,
    //     ).then((response) => {
    //       console.log(response)
    //         if (response.status === 200) {
    //             this.hideAlert()
    //             return response.text().then((text) => {
    //               console.log(errors)
    //                 const data = text && JSON.parse(text)
    //                 if (data.success)
    //                     this.setState({
    //                         snackStatus: {
    //                             open: true,
    //                             severity: 'success',
    //                             text: 'E-mail enviado com sucesso!',
    //                         },
    //                     })
    //                     this.setState({
    //                         snackStatus: {
    //                             open: true,
    //                             severity: 'error',
    //                             text: 'Ocorreu um erro ao realizar a operação!',
    //                         },
    //                     })
    //                 return data
    //             }).catch((error) => {

    //             })
    //         } else {
    //             return response.text().then((text) => {
    //                 const error = text && JSON.parse(text)
    //                 console.log('Falha: ', error)
    //                 return Promise.reject(error)
    //             })
    //         }
    //     })
    // }

    handleGetCPF() {
        if (this.state.cpf.length === 0) {
            API.GetCurrentConsumer()
                .then((response) => {
                    this.handleSendEmail(response?.cpf)
                })
                .catch((error) => {
                    console.error('Ocorreu um erro:', error)
                })
        } else {
            let cpf = this.state.cpf
            let cpfState = cpf.replace(/(\.|\/|\-)/g, '')
            this.handleSendEmail(cpfState)
            this.hideAlert()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.requestPasswordReset &&
            prevProps.requestPasswordReset !== this.props.requestPasswordReset
        ) {
            if (this.props.requestPasswordReset.errors.length > 0) {
                let values = {
                    confirmTitle: '',
                    confirmButton: '',
                    confirmText: '',
                }
                switch (this.props.requestPasswordReset.errors[0].message) {
                    case 'PHONENUMBER_DOES_NOT_VALIDATED':
                        values = {
                            confirmTitle: 'phone',
                            confirmButton: 'Continuar',
                            confirmText:
                                "Você ainda não validou o celular cadastrado para login informado. Será enviado um novo sms com o código de confirmação para você. Clique em 'Continuar' para prosseguir com a validação do seu cadastro.",
                        }
                        break
                    case 'EMAIL_DOES_NOT_VALIDATED':
                        values = {
                            confirmTitle: 'email',
                            confirmButton: 'Enviar e-mail',
                            confirmText:
                                'Você ainda não confirmou o seu e-mail. Clique no botão abaixo para receber o e-mail de confirmação de conta.',
                        }
                        break
                    case 'Token - INVALID_VALUE':
                        this.setState({
                            snackStatus: {
                                open: true,
                                severity: 'error',
                                text: 'CNPJ inválido.',
                            },
                        })

                        break

                    default:
                        this.setState({
                            snackStatus: {
                                open: true,
                                severity: 'error',
                                text: 'CPF  inválido.',
                            },
                        })

                        break
                }

                if (
                    values.confirmTitle &&
                    values.confirmButton &&
                    values.confirmText
                ) {
                    this.setState({
                        alert: (
                            <Alert
                                warning
                                style={{
                                    display: 'block',
                                    marginTop: '-100px',
                                }}
                                title={'Atenção!'}
                                onConfirm={() =>
                                    values.confirmTitle === 'email'
                                        ? this.handleSendEmail()
                                        : values.confirmTitle === 'phone'
                                        ? this.handleSendSMS()
                                        : this.hideAlert()
                                }
                                confirmBtnCssClass={
                                    this.props.classes.button +
                                    ' ' +
                                    this.props.classes.greenButtonColor
                                }
                                confirmBtnText={values.confirmButton}
                            >
                                {values.confirmText}
                            </Alert>
                        ),
                    })
                }
            }
            this.formik.current && this.formik.current.resetForm()
        }

        if (
            this.props.resetPassword &&
            prevProps.resetPassword !== this.props.resetPassword
        ) {
            if (this.props.resetPassword.success) {
                this.setState({
                    alert: (
                        <Alert
                            success
                            style={{ display: 'block', marginTop: '-100px' }}
                            title={null}
                            onConfirm={() =>
                                this.props.history.push('auth/login')
                            }
                            confirmBtnCssClass={
                                this.props.classes.button +
                                ' ' +
                                this.props.classes.greenButtonColor
                            }
                            confirmBtnText={'OK'}
                        >
                            {this.props.t('FIELD_ALERT_SUCCESS')}
                        </Alert>
                    ),
                })
                this.formik.current && this.formik.current.resetForm()
            } else {
                this.setState({
                    alert: (
                        <Alert
                            warning
                            style={{ display: 'block', marginTop: '-100px' }}
                            title={'Atenção!'}
                            onConfirm={() => this?.props?.history?.goBack()}
                            confirmBtnCssClass={
                                this.props.classes.button +
                                ' ' +
                                this.props.classes.greenButtonColor
                            }
                            confirmBtnText={'OK'}
                        >
                            {this?.props?.resetPassword.data?.token
                                ? this.props.t(
                                      this?.props?.resetPassword.data?.token?.replace(
                                          '.',
                                          '',
                                      ),
                                  )
                                : this.props.resetPassword.errors.map(
                                      (error, index) => (
                                          (this.jsonWithoutEscapes =
                                              error?.message?.replace(
                                                  /\\/g,
                                                  '',
                                              )),
                                          (this.jsonArray = JSON.parse(
                                              this.jsonWithoutEscapes,
                                          )),
                                          (this.errorMessage =
                                              this.jsonArray[0]),
                                          (
                                              <div key={index}>
                                                  {this.props.t(
                                                      this.errorMessage?.error_description.replace(
                                                          '.',
                                                          '',
                                                      ),
                                                  )}
                                              </div>
                                          )
                                      ),
                                  )}
                        </Alert>
                    ),
                })
            }
        }

        if (
            this.props.resetPasswordFailed &&
            prevProps.resetPasswordFailed !== this.props.resetPasswordFailed
        ) {
            this.setState({
                alert: (
                    <Alert
                        warning
                        style={{ display: 'block', marginTop: '-100px' }}
                        title={'Atenção!'}
                        onConfirm={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button +
                            ' ' +
                            this.props.classes.greenButtonColor
                        }
                        confirmBtnText={'OK'}
                    >
                        <div>
                            {this.props.t(this.props.resetPasswordFailed)}
                        </div>
                    </Alert>
                ),
            })
        }
    }

    handleRequest = (values) => {
        this.setState({ cpf: values.login })
        this.props.GetResetPasswordToken(
            values.login.replace(/(\.|\/|\-)/g, ''),
        )
    }

    handleCancel = () => {
        this.props.history.push(`/auth/login`)
    }

    handleReset = (values) => {
        this.props.PostResetPassword(values)
    }

    hideAlert = () => this.setState({ alert: null })

    handleCloseSnack = () => {
        this.setState({
            snackStatus: {
                ...this.state.snackStatus,
                open: false,
            },
        })
    }

    handleMsg = (text, severity) => {
        this.setState({
            snackStatus: {
                text: text,
                severity: severity,
                open: true,
            },
        })
    }

    render() {
        const { login, token, newPassword, confirmPassword } = this.state
        const values = { token, newPassword, confirmPassword }

        return (
            <S.Container>
                {this.state.alert}

                <SnackDefault
                    snackStatus={this.state.snackStatus}
                    handleCloseSnack={() => this.handleCloseSnack()}
                />

                <GridContainer
                    justify="center"
                    className={styles.Container}
                    style={{ paddingTop: '0' }}
                >
                    <S.HeaderBox
                        className={styles.parent}
                        backgroundImage={
                            GetThemeColorFromStorage()?.images?.internalPages
                                ?.menuBackground?.url
                        }
                    >
                        <div className={styles.headerContainer}>
                            <div className={styles.logoBox}>
                                <img
                                    className={styles.logo}
                                    src={germiniLogoInterna}
                                    alt="Logo"
                                />
                            </div>
                        </div>
                    </S.HeaderBox>
                    <GridItem
                        xs={11}
                        sm={12}
                        md={8}
                        className={styles.GridItem}
                    >
                        {_.isEmpty(this.state.token) ? (
                            <Formik
                                ref={this.formik}
                                render={(props) => (
                                    <RequestPasswordResetForm
                                        history={this.props.history}
                                        handleCancel={this.handleCancel}
                                        handleMsg={(text, severity) =>
                                            this.handleMsg(text, severity)
                                        }
                                        {...props}
                                    />
                                )}
                                validationSchema={requestValidationSchema}
                                initialValues={{ login }}
                                onSubmit={this.handleRequest.bind(this)}
                            />
                        ) : (
                            <Formik
                                ref={this.formik}
                                render={(props) => (
                                    <ResetPasswordForm {...props} />
                                )}
                                validationSchema={resetValidationSchema}
                                initialValues={values}
                                onSubmit={this.handleReset.bind(this)}
                                enableReinitialize={true}
                            />
                        )}
                    </GridItem>
                </GridContainer>
            </S.Container>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        requestPasswordReset: state.users.requestPasswordReset,
        resetPassword: state.users.resetPassword,
        resetPasswordFailed: state.users.resetPasswordFailed,
    }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            GetResetPasswordToken,
            GetCurrentConsumer,
            //GetConsumerByToken,
            PostResetPassword,
            SweetAlert,
            HideAlert,
            ShowLoader,
            HideLoader,
        },
        dispatch,
    )

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(extendedFormsStyle),
    withTranslation(),
    connectRouterState({ token: '' }),
)(ResetPassword)

/* eslint-disable import/first */
import { Box, styled } from "@mui/material";
export var InputStack = styled(Box)({
    position: "relative",
    width: "100%",
    "& .label-container": {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        height: 0,
        opacity: 0,
        transform: "translateY(0)",
        transition: "all 0.2s ease-out",
        overflow: "hidden",
        zIndex: 1,
        "&.has-label": {
            height: "20px",
            opacity: 1,
            transform: "translateY(-25px)",
        },
    },
    "& .MuiFormControl-root": {
        width: "100%",
    },
    "& .message-container": {
        minHeight: "20px",
        marginTop: "4px",
    },
});

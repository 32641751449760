/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { GetCurrentConsumer as getConsumer } from '../redux/api/user.js'
import Axios from 'axios'
import { useLocation } from 'react-router-dom'

const ConsumerContext = createContext({})
const ConsumerProvider = ({ children }) => {
    const dispatch = useDispatch()
    const [userData, setUserData] = React.useState({})

    const location = useLocation()

    async function GetCurrentConsumer() {
        if (
            location?.pathname &&
            location?.pathname !== '/register' &&
            location?.pathname !== '/auth/login' &&
            location?.pathname !== '/ConfirmRegistration' &&
            location?.pathname !== '/authenticateAccess'
        ) {
            const storageConsumer = JSON.parse(localStorage.getItem('user'))
            setUserData(storageConsumer)

            const response = await getConsumer()

            getAllowNegotiations()

            if (response) {
                localStorage.setItem('user', JSON.stringify(response))
                setUserData(response)
            }
        }
    }

    async function getAllowNegotiations() {
        const response = await Axios.get(`/Negotiation/GetExtractList`)
        if (response?.data?.results?.length > 0) {
            return localStorage?.setItem('showNegotiations', true)
        } else {
            return localStorage?.removeItem('showNegotiations')
        }
    }

    useMemo(async () => {
        if (
            location?.pathname &&
            location?.pathname !== '/register' &&
            location?.pathname !== '/auth/login' &&
            location?.pathname !== '/ConfirmRegistration' &&
            location?.pathname !== '/authenticateAccess' &&
            location?.pathname !== '/confirmRegistration' &&
			location?.pathname !== '/resetpassword'
        ) {
            await getAllowNegotiations()
        }
    }, [location?.pathname])

    useEffect(() => {
        if (
            location?.pathname &&
            location?.pathname !== '/register' &&
            location?.pathname !== '/auth/login' &&
            location?.pathname !== '/ConfirmRegistration' &&
            location?.pathname !== '/authenticateAccess' &&
            location?.pathname !== '/confirmRegistration' &&
			location?.pathname !== '/resetpassword'
        ) {
            const storedUserData = JSON.parse(localStorage?.getItem('user'))
            if (storedUserData) {
                setUserData(storedUserData)
            } else {
                getAllowNegotiations()
            }
        }
    }, [])

    useEffect(() => {
        if (
            location?.pathname &&
            location?.pathname !== '/register' &&
            location?.pathname !== '/auth/login' &&
            location?.pathname !== '/ConfirmRegistration' &&
            location?.pathname !== '/authenticateAccess' &&
            location?.pathname !== '/confirmRegistration'
        ) {
            const intervalId = setInterval(() => {
                GetCurrentConsumer()
            }, 60000)
            return () => clearInterval(intervalId)
        }
    }, [dispatch])

    return (
        <ConsumerContext.Provider
            value={{
                GetCurrentConsumer,
                userData,
                setUserData,
            }}
        >
            {children}
        </ConsumerContext.Provider>
    )
}

function useConsumer() {
    const context = useContext(ConsumerContext)
    return context
}

export { ConsumerProvider, useConsumer }

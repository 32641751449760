import React from 'react'

import {
    formatBRCurrency,
    formateCustomDate,
    LupaIcon,
    MuiBox,
    MuiIconButton,
    MuiLegend,
    MuiTooltip,
    MuiTypography,
    TableConsumer,
} from '../../../../components/storybook'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'
import { useMediaQuery } from '@mui/material'
import { formatDate, formatDateModal } from '../../functions'
import { useTranslation } from 'react-i18next'
import { statusColors, statusTooltip } from '../../utils'
import { useHistory } from 'react-router-dom'

export function Table({
    isLoadingData,
    payrollDebitData,
    tablePagination,
    getPaginatedPayrollDebit,
}) {
    const { t } = useTranslation()
    const history = useHistory()

    const matches = useMediaQuery('(max-width:600px)')

    return (
        <MuiBox
            mt={2}
            sx={{
                overflow: 'auto!important',
                '& .table tr:nth-child(even)': {
                    backgroundColor: '#FFFFFF!important',
                },
                '& .MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignRight.MuiTableCell-sizeMedium':
                    {
                        borderRadius: '0px!important',
                    },
            }}
        >
            <TableConsumer
                columns={[
                    {
                        id: 'id',
                        label: '',

                        render: (rowData) => <></>,
                    },
                    {
                        align: 'left',
                        id: 'Data da compra',
                        label: 'Data da compra',
                        render: (rowData) => (
                            <MuiBox
                                sx={{
                                    width: '200px',
                                    overflow: 'hidden',
                                }}
                            >
                                <>
                                    <MuiTypography
                                        variant="body2"
                                        sx={{
                                            display: 'flex',
                                            color: '#636363',
                                        }}
                                    >
                                        {formatDate(rowData?.date) ?? '-'}
                                    </MuiTypography>

                                    <MuiTypography
                                        variant="body2"
                                        sx={{
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            WebkitLineClamp: 1,
                                            color: '#636363',
                                            fontSize: 12,
                                        }}
                                    >
                                        {formateCustomDate(
                                            rowData?.date,
                                            'hh:mm',
                                        ) ?? '-'}
                                    </MuiTypography>
                                </>
                            </MuiBox>
                        ),
                    },
                    {
                        align: 'left',
                        id: 'Parceiro',
                        label: 'Parceiro',
                        render: (rowData) => (
                            <MuiBox>
                                <>
                                    <MuiTypography
                                        variant="body2"
                                        sx={{
                                            display: 'flex',
                                            color: '#636363',
                                        }}
                                    >
                                        {rowData?.partner ?? '-'}
                                    </MuiTypography>
                                </>
                            </MuiBox>
                        ),
                    },

                    {
                        align: 'left',
                        id: 'Valor',
                        label: 'Valor',
                        render: (rowData) => (
                            <MuiTypography
                                variant="body2"
                                sx={{
                                    display: 'flex',
                                    color: '#636363',
                                }}
                            >
                                {rowData?.value
                                    ? formatBRCurrency(rowData?.value)
                                    : '-'}
                            </MuiTypography>
                        ),
                    },
                    {
                        align: 'right',
                        id: 'Ações',
                        label: 'Ações',
                        padding: '0px!important',
                        render: (rowData) => (
                            <MuiIconButton
                                onClick={() => {
                                    history.push(
                                        `/payrollDebitDetails/${rowData.id}`,
                                    )
                                }}
                                sx={{
                                    backgroundColor:
                                        GetThemeColorFromStorage()?.navigation
                                            ?.buttons?.backgroundColor,
                                    borderRadius: '0px 5px 5px 0px',
                                    padding: '20px 12px',
                                    ':hover': {
                                        backgroundColor:
                                            GetThemeColorFromStorage()
                                                ?.navigation?.buttons
                                                ?.backgroundColor,
                                    },
                                }}
                            >
                                <LupaIcon size={15} color="#FFFFFF" />
                            </MuiIconButton>
                        ),
                    },
                ]}
                firstItemPadding={'10px 15px!important'}
                padding="10px 5px"
                notFoundMessage={'Nenhum registro encontrado'}
                onChangeRowsPerPage={() => {}}
                showPagination
                loading={isLoadingData}
                data={payrollDebitData?.history || []}
                totalItems={tablePagination?.totalPages}
                defaultRows={10}
                onChangePage={(page) => {
                    getPaginatedPayrollDebit('page', page, tablePagination)
                }}
                changeRowsPerPage={(rowsPerPage) => {
                    getPaginatedPayrollDebit(
                        'pageSize',
                        rowsPerPage,
                        tablePagination,
                    )
                }}
                overflow={(matches && 'auto') || 'hidden'}
                defaultColor={
                    GetThemeColorFromStorage()?.navigation?.buttons
                        ?.backgroundColor
                }
            />
        </MuiBox>
    )
}

/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { NegotiationCalendarIcon } from "../Icons";
import { InputStack } from "../Stack/InputStack";
import { MuiBox } from "../Box";
import { MuiTypography } from "../Typography";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
dayjs.locale("pt-br");
export function MuiDatePicker(_a) {
    var _b;
    var defaultColor = _a.defaultColor, showLabel = _a.showLabel, disabled = _a.disabled, textLabel = _a.textLabel, formatText = _a.formatText, defaultValue = _a.defaultValue, height = _a.height, width = _a.width, onChange = _a.onChange, background = _a.background, minDate = _a.minDate, maxDate = _a.maxDate, value = _a.value, openPickerIcon = _a.openPickerIcon, rest = __rest(_a, ["defaultColor", "showLabel", "disabled", "textLabel", "formatText", "defaultValue", "height", "width", "onChange", "background", "minDate", "maxDate", "value", "openPickerIcon"]);
    var color = defaultColor !== null && defaultColor !== void 0 ? defaultColor : "#F58B3C";
    var hasLabel = Boolean(showLabel && textLabel);
    function CustomIcon() {
        return _jsx(NegotiationCalendarIcon, { size: 20, color: disabled ? "#989898" : color });
    }
    return (_jsxs(InputStack, { children: [_jsx(MuiBox, __assign({ className: "label-container ".concat(hasLabel ? "has-label" : "") }, { children: hasLabel && (_jsx(MuiTypography, __assign({ variant: "body2", color: "#989898", style: {
                        fontSize: "13px",
                        lineHeight: "20px",
                    } }, { children: textLabel }))) })), _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsx(DatePicker, __assign({ disabled: disabled, value: value ? dayjs(value) : null, onChange: onChange, minDate: minDate ? dayjs(minDate) : null, maxDate: maxDate ? dayjs(maxDate) : null, slots: { openPickerIcon: openPickerIcon !== null && openPickerIcon !== void 0 ? openPickerIcon : CustomIcon }, format: "DD/MM/YYYY", slotProps: {
                        textField: {
                            size: "small",
                            placeholder: textLabel !== null && textLabel !== void 0 ? textLabel : "DD/MM/YYYY",
                            sx: {
                                width: "100%",
                                backgroundColor: (_b = ((disabled && "#F2F2F2") || background)) !== null && _b !== void 0 ? _b : "#FFFFFF",
                                "& .MuiOutlinedInput-root": {
                                    height: "40px",
                                    "& input": {
                                        padding: "8px 15px",
                                        height: "24px",
                                    },
                                    "& fieldset": {
                                        borderColor: "#989898",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: color,
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: color,
                                    },
                                },
                                ".MuiInputBase-root": {
                                    paddingRight: "14px",
                                },
                            },
                        },
                    } }, rest)) }))] }));
}

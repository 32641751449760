import React from 'react'

import {
    formatBRCurrency,
    LabelText,
    MuiBox,
    MuiStack,
    MuiTypography,
} from '../../../../components/storybook'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'
import { useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

export function CardValues({ payrollDebitData }) {
    const { t } = useTranslation()
    const history = useHistory()

    const matches = useMediaQuery('(max-width:600px)')

    console.log({ GetThemeColorFromStorage: GetThemeColorFromStorage() })

    return (
        <MuiStack
            mt={3}
            spacing={2}
            direction={matches ? 'column' : 'row'}
            width={'100%'}
        >
            <MuiBox
                sx={{
                    background:
                        GetThemeColorFromStorage()?.navigation?.buttons
                            ?.backgroundColor,
                    padding: 1,
                    borderRadius: 2,
                    '& .MuiTypography-root': {
                        color: '#FFFFFF!important',
                        fontWeight: '300',
                    },
                }}
                width={'100%'}
            >
                <LabelText
                    label={'Limite atual'}
                    value={
                        <MuiBox mt={-1}>
                            <MuiTypography
                                sx={{
                                    fontWeight: '500!important',
                                    marginTop: '-9!important',
                                }}
                            >
                                {formatBRCurrency(
                                    payrollDebitData?.actualLimit ?? 0,
                                )}
                            </MuiTypography>
                        </MuiBox>
                    }
                />
            </MuiBox>{' '}
            <MuiBox
                sx={{
                    background:
                        GetThemeColorFromStorage()?.navigation?.buttons
                            ?.backgroundColor,
                    padding: 1,
                    borderRadius: 2,
                    '& .MuiTypography-root': {
                        color: '#FFFFFF!important',
                        fontWeight: '300',
                    },
                }}
                width={'100%'}
            >
                <LabelText
                    label={'Valor utilizado'}
                    value={
                        <MuiBox mt={-1}>
                            <MuiTypography sx={{ fontWeight: '500!important' }}>
                                {formatBRCurrency(
                                    payrollDebitData?.usedValue ?? 0,
                                )}
                            </MuiTypography>
                        </MuiBox>
                    }
                />
            </MuiBox>{' '}
            <MuiBox
                sx={{
                    background:
                        GetThemeColorFromStorage()?.navigation?.buttons
                            ?.backgroundColor,
                    padding: 1,
                    borderRadius: 2,
                    '& .MuiTypography-root': {
                        color: '#FFFFFF!important',
                        fontWeight: '300',
                    },
                }}
                width={'100%'}
            >
                <LabelText
                    label={'Saldo disponivel'}
                    value={
                        <MuiBox mt={-1}>
                            <MuiTypography sx={{ fontWeight: '500!important' }}>
                                {formatBRCurrency(
                                    payrollDebitData?.availableBalance ?? 0,
                                )}
                            </MuiTypography>
                        </MuiBox>
                    }
                />
            </MuiBox>
        </MuiStack>
    )
}

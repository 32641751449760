/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash.debounce'

// CONTEXT

// MATERIAL
import { FormControl } from '@material-ui/core'

// Styles
import {
    Button,
    CalendarIcon,
    GridContainer,
    GridItem,
    MuiAutocomplete,
    MuiBox,
    MuiDatePicker,
    MuiStack,
} from '../../../components/storybook'
import { GetThemeColorFromStorage } from '../../../redux/api/themeColor'

const SearchForm = ({ getFilteredDebit, partners }) => {
    const { t } = useTranslation()
    const [theme, setTheme] = useState(GetThemeColorFromStorage())

    const { control, handleSubmit, watch, setValue } = useForm({})

    const watchAllFields = watch()

    const handleFormSubmit = async (data) => {
        const filter = {
            InitialDate:
                data?.startDate && data?.startDate
                    ? new Date(data?.startDate).toISOString()
                    : null,
            FinalDate:
                data?.endDate && data?.endDate
                    ? new Date(data?.endDate).toISOString()
                    : null,
            PartnerCNPJ: data?.name_cnpj,
        }

        await getFilteredDebit(filter)
    }

    const handleClear = () => {
        setValue('startDate', '')
        setValue('endDate', '')
        setValue('name_cnpj', null)

        getFilteredDebit({})
    }

    useEffect(() => {
        setTheme(GetThemeColorFromStorage())
    }, [])

    const handlePartnerChange = (event, value) => {
        setValue('name_cnpj', value?.value)
    }

    function CustomIcon() {
        return (
            <CalendarIcon
                size={20}
                color={theme?.navigation?.buttons?.backgroundColor}
            />
        )
    }

    return (
        <MuiBox
            sx={{
                backgroundColor: '#F8F8F8',
                padding: '10px 20px 20px 20px',
                borderRadius: '5px',
                width: '100%',
                '.MuiInputBase-input.Mui-disabled': {
                    opacity: 1,
                    background: '#F2F2F2 !important',
                },
            }}
            mt={3}
        >
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <GridContainer spacing={1}>
                    <GridItem lg={2.7} xs={12} md={2.7} mt={3.2}>
                        <MuiBox
                            sx={{
                                '& .MuiFormControl-root.MuiTextField-root': {
                                    minWidth: 0,
                                },
                            }}
                        >
                            <Controller
                                control={control}
                                name="startDate"
                                render={({ ...field }) => (
                                    <MuiDatePicker
                                        {...field}
                                        placeholder="De"
                                        showLabel
                                        textLabel={
                                            watchAllFields?.startDate
                                                ? 'De'
                                                : ' '
                                        }
                                        defaultValue={null}
                                        background="#F6F6F6!important"
                                        defaultColor={
                                            theme?.navigation?.buttons
                                                ?.backgroundColor
                                        }
                                        width="100%!important"
                                        openPickerIcon={CustomIcon}
                                    />
                                )}
                            />
                        </MuiBox>
                    </GridItem>
                    <GridItem lg={2.7} xs={12} md={2.7} mt={3.2}>
                        <MuiBox
                            sx={{
                                '& .MuiBox-root': { paddingBottom: '-8px' },
                                '& .MuiFormControl-root.MuiTextField-root': {
                                    minWidth: 0,
                                },
                            }}
                        >
                            <Controller
                                control={control}
                                name="endDate"
                                render={({ ...field }) => (
                                    <MuiDatePicker
                                        {...field}
                                        placeholder="Até"
                                        showLabel
                                        textLabel={
                                            watchAllFields?.endDate
                                                ? 'Até'
                                                : ' '
                                        }
                                        defaultValue={'De'}
                                        background="#F6F6F6!important"
                                        defaultColor={
                                            theme?.navigation?.buttons
                                                ?.backgroundColor
                                        }
                                        openPickerIcon={CustomIcon}
                                    />
                                )}
                            />
                        </MuiBox>
                    </GridItem>

                    <GridItem lg={6.6} xs={12} md={6.6}>
                        <FormControl fullWidth size="small" variant="outlined">
                            <Controller
                                name="name_cnpj"
                                defaultValue=""
                                control={control}
                                render={({ onChange, value }) => (
                                    <MuiAutocomplete
                                        options={partners}
                                        required
                                        textLabel={
                                            watchAllFields?.name_cnpj
                                                ? 'Nome ou CNPJ do parceiro'
                                                : ' '
                                        }
                                        showLabel
                                        value={watchAllFields?.name_cnpj}
                                        onChange={(_e, newValue) => {
                                            handlePartnerChange(_e, newValue)
                                            onChange(newValue?.value || '')
                                        }}
                                        placeholder="Nome ou CNPJ do parceiro"
                                        defaultColor={
                                            theme?.navigation?.buttons
                                                ?.backgroundColor
                                        }
                                        sx={{ background: '#F6F6F6!important' }}
                                        defaultValue={null}
                                    />
                                )}
                            />
                        </FormControl>
                    </GridItem>
                </GridContainer>

                <MuiStack
                    direction="row"
                    spacing={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                    pr={1.5}
                >
                    <Button
                        text={t('BUTTON_FIND')}
                        typeVariation="TextButton"
                        type="submit"
                        sx={{
                            color: '#FFFFFF!important',
                            textTransform: 'unset',
                            width: '100%',
                            height: '40px',
                            padding: '5px 15px',
                            backgroundColor: '#329EF1!important',
                            maxWidth: '85px',
                        }}
                    />
                    <Button
                        text="Limpar"
                        typeVariation="CleanButton"
                        onClick={handleClear}
                        sx={{
                            border: '1px solid #C8C8C8',
                            width: '100%',
                            height: '40px',
                            color: '#C81010!important',
                            textTransform: 'unset',
                            maxWidth: '85px',
                        }}
                    />
                </MuiStack>
            </form>
        </MuiBox>
    )
}

export default SearchForm

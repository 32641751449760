import React from 'react'
import queryString from 'query-string'

export const connectRouterState =
    (defaultURLState) => (Component) => (props) => {
        const { history, location } = props
        const urlState = {
            ...defaultURLState,
            ...queryString?.parse(location?.search),
        }

        const setURLState = (newState) => {
            const finalState = { ...urlState, ...newState }

            Object.keys(finalState).forEach(function (k) {
                if (
                    // don't save some state values if it meets the conditions below
                    !finalState[k] || // falsy
                    finalState[k] === '' || // string
                    (Array.isArray(finalState[k]) && !finalState[k].length) || // array
                    finalState[k] === defaultURLState[k] // same as default state, unnecessary
                ) {
                    delete finalState[k] // drop query params with new values = falsy
                }
            })

            return history.push({
                search: `?${queryString.stringify(finalState)}`,
            })
        }
        return (
            <Component
                setURLState={setURLState}
                urlState={urlState}
                {...props}
            />
        )
    }

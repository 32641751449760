import Axios from 'axios'

export default class PayrollDebitService {
    async getPayrollDebitHistory() {
        const response = await Axios.get(
            `/Consumer/GetPayrollDebitHistory?pageSize=10&page=1`,
        )
        return response?.data?.data
    }
    async getPaginationDebit({ pageSize, page }) {
        const response = await Axios.get(
            `/Consumer/GetPayrollDebitHistory?&pageSize=${pageSize}&page=${page}`,
        )
        return response?.data?.data
    }
    async getFilteredDebit({ pageSize, page, ...filter }) {
        const queryParams = {
            page,
            pageSize,
            ...filter,
        }

        const filteredParams = Object.fromEntries(
            Object.entries(queryParams).filter(
                ([_, value]) =>
                    value !== undefined && value !== null && value !== '',
            ),
        )

        const query = Object.entries(filteredParams)
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join('&')

        const response = await Axios.get(
            `/Consumer/GetPayrollDebitHistory?${query}`,
        )
        return response?.data?.data
    }
}

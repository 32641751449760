import { CONSUMER_ALREADY_ACTIVE, CONSUMER_ALREADY_ACTIVE_CNPJ, CPF_NOT_ALLOWED, DUPLICATED, DUPLICATED_CNPJ, EMAIL_DOES_NOT_VALIDATED, PHONENUMBER_DOES_NOT_VALIDATED, PHONENUMBER_DUPLICATED } from "../helpers/utils"

export const handleErrorAlert = (errors, data, t, setModalAlert) => {
    if (errors && errors.length > 0) {
        const errorMessage = errors[0].message

        switch (errorMessage) {
            case EMAIL_DOES_NOT_VALIDATED:
                setModalAlert((prevState) => ({
                    ...prevState,
                    modalResendEmailProps: {
                        open: true,
                        email: data?.email,
                    },
                }))
                return

            case DUPLICATED:
                setModalAlert((prevState) => ({
                    ...prevState,
                    modalProps: {
                        open: true,
                        text: t('CPF_ALREADY_REGISTERED_IN_THE_PROGRAM'),
                    },
                }))
                return

            case CONSUMER_ALREADY_ACTIVE:
                setModalAlert((prevState) => ({
                    ...prevState,
                    modalProps: {
                        open: true,
                        text: t('ALREADY_REGISTERED_IN_THE_PROGRAM'),
                    },
                }))
                return

            case CONSUMER_ALREADY_ACTIVE_CNPJ:
                setModalAlert((prevState) => ({
                    ...prevState,
                    modalProps: {
                        open: true,
                        text: t('ALREADY_REGISTERED_IN_THE_PROGRAM'),
                    },
                }))
                return

            case PHONENUMBER_DUPLICATED:
                setModalAlert((prevState) => ({
                    ...prevState,
                    modalProps: {
                        open: true,
                        text: t('DUPLICATED_PHONE_NUMBER'),
                    },
                }))
                return

            case DUPLICATED_CNPJ:
                setModalAlert((prevState) => ({
                    ...prevState,
                    modalProps: {
                        open: true,
                        text: t('DUPLICATED_CNPJ'),
                    },
                }))
                return

            case PHONENUMBER_DOES_NOT_VALIDATED:
                setModalAlert((prevState) => ({
                    ...prevState,
                    modalResendSmsProps: { open: true },
                }))
                return

            case CPF_NOT_ALLOWED:
                setModalAlert((prevState) => ({
                    ...prevState,
                    modalAdmin: { open: true },
                }))
                return

            default:
                break
        }
    }
}

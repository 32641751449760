import React, { useEffect } from 'react'
import { MuiBox } from '../../components/storybook'
import DocumentTitle from 'react-document-title'
import SearchForm from './SearchForm/index.js'
import Section from '../../components/Section/Section'
import * as S from './styles'
import { getProgramTitle } from '../../utils/utils.js'
import { Table } from './components/Table/index.jsx'
import usePayrollDebit from './Hooks/usePayrollDebit.js'
import { CardValues } from './components/CardValues/index.jsx'

const PayrollDebitChild = () => {
    const {
        getFilteredDebit,
        getPayrollDebitData,
        getPaginatedPayrollDebit,
        payrollDebitData,
        tablePagination,
        isLoadingData,
        partners,
        fetchPartners,
        watchAllFields,
    } = usePayrollDebit()

    useEffect(() => {
        getPayrollDebitData()
        fetchPartners(watchAllFields?.name_cnpj ?? '')
    }, [])

    return (
        <div>
            <DocumentTitle title={getProgramTitle('Débito em folha')} />
            <MuiBox mt={3}>
                <Section title="Débito em folha" />
            </MuiBox>
            <CardValues payrollDebitData={payrollDebitData} />

            <S.ContainerSecundary>
                <SearchForm
                    getFilteredDebit={getFilteredDebit}
                    payrollDebitData={payrollDebitData}
                    partners={partners}
                    fetchPartners={fetchPartners}
                />
            </S.ContainerSecundary>

            <MuiBox
                sx={{
                    width: '100%',
                    '& ."MuiBox-root': { background: '#000!important' },
                }}
                mt={3}
            >
                <Table
                    isLoadingData={isLoadingData}
                    payrollDebitData={payrollDebitData}
                    tablePagination={tablePagination}
                    getPaginatedPayrollDebit={getPaginatedPayrollDebit}
                />
            </MuiBox>
        </div>
    )
}

export default PayrollDebitChild

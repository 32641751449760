import axios from 'axios'

// GET api/Consumer/GetIndications
export const GetContact = async () => {
    try {
        const response = await axios.get(
            `/ProgramRules/ProgramAdministrator/GetContactInfo`,
        )
        return response?.data
    } catch (error) {
        console.log(error)
    }
}

import React from 'react'
import * as moment from 'moment'
import DocumentTitle from 'react-document-title'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { withStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'

import { CustomButtons } from '../../components/CustomButtons'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import MaterialTable from 'components/CustomMaterialTable/CustomMaterialTable.jsx'
import Section from 'components/Section/Section'
import TextField from 'components/CustomInput/CustomTextField'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'
// UTILS
import compose from 'utils/compose'
import { numberFormatText } from 'utils/utils'

// REDIX INIT
import { connectRouterState } from 'utils/connectRouterState'

import {
    GetInvoiceById,
    SweetAlert,
    HideAlert,
    ShowLoader,
    HideLoader,
} from 'redux/actions'

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'
import { GridBox, ModalButton } from './styles'
import AppModal from './components/AppModal'
import { getProgramTitle } from '../../utils/utils'
import { MuiBox, MuiLoader, MuiTypography } from '../../components/storybook'

const shopHistoryStyle = {
    ...extendedFormsStyle,
    inputDate: {
        height: '3px',
        fontSize: '13px',
        paddingLeft: '8px',
    },
    labelTexF: {
        lineHeight: '0 !important',
    },
    inputHeight: {
        height: '9px',
    },
}

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: grayColor[2],
        },
        '& label': {
            fontSize: '14px',
            lineHeight: 1.2,
            minWidth: 300,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: grayColor[2],
        },
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: grayColor[2],
            },
            '&.Mui-focused fieldset': {
                borderColor: grayColor[2],
            },
        },
    },
})(TextField)

class ShopHistoryDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            invoiceById: [],
            open: false,
            showModalButton: false,
            totalPoints: 0,
            AppId: '',
            AppType: '',
        }

        if (localStorage.getItem('i18nextLng') !== null) {
            moment.locale(localStorage.getItem('i18nextLng'))
        } else {
            moment.locale('pt-BR')
        }
    }

    componentDidMount() {
        const id = this.props.match && this.props.match.params.id
        if (id) {
            // this.props.ShowLoader(<Loader />)
            this.props.GetInvoiceById(id)
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.payrollDebitData !== this.props.payrollDebitData) {
            this.setState({
                payrollDebitData: this.props.invoiceById,
                isLoadingData: false,
            })
        }
        if (prevProps.invoiceById !== this.props.invoiceById) {
            this.setState({
                invoiceById: this.props.invoiceById,
                payrollDebitData: this.props.invoiceById,
                isLoadingData: false,
            })
            this.props.HideLoader()
            var number = 0
            this.props.invoiceById.items.forEach((item) => {
                number = number + Math.trunc(item.points)
            })
            this.setState({ totalPoints: number })
        }
        if (prevState.invoiceById !== this.state.invoiceById) {
            if (
                this.state.invoiceById?.generated &&
                this.state.invoiceById?.generated[0]?.redeemedValue === 0
            ) {
                this.setState({
                    AppId:
                        this.state.invoiceById?.generated &&
                        this.state.invoiceById?.generated[0]?.id,
                })
                this.setState({
                    AppType:
                        this.state.invoiceById?.generated &&
                        this.state.invoiceById?.generated[0]?.type?.key,
                })
                if (
                    this.state.invoiceById?.generated &&
                    this.state.invoiceById?.generated[0]?.approvalChannel
                        ?.value === 'APP'
                ) {
                    this.setState({ showModalButton: true })
                }
            }
            if (
                this.state.invoiceById?.generated &&
                this.state.invoiceById?.generated[1]?.redeemedValue !== 0
            ) {
                this.setState({
                    AppId:
                        this.state.invoiceById?.generated &&
                        this.state.invoiceById?.generated[1]?.id,
                })
                this.setState({
                    AppType:
                        this.state.invoiceById?.generated &&
                        this.state.invoiceById?.generated[1]?.type?.key,
                })
                if (
                    this.state.invoiceById?.generated &&
                    this.state.invoiceById?.generated[1]?.approvalChannel
                        ?.value === 'APP'
                ) {
                    this.setState({ showModalButton: true })
                }
            }
        }
    }

    componentWillUnmount() {
        this.props.HideAlert()
    }

    handleToggle = () => {
        this.setState({ open: !this.state.open })
    }

    handleGoBack = () => {
        console.log(this.props.history)

        this.props.history.goBack()
    }

    render() {
        const { t, open } = this.props
        const { invoiceById } = this.state

        const currencySymbol =
            invoiceById?.redeemed?.[0]?.type?.key === '1' &&
            this.props?.generalRulesData?.programCurrencySymbol + ' '

        const currencyMask = new Intl.NumberFormat('pt-br', {
            style: 'currency',
            currency: 'BRL',
        })

        const formatCurrency = (value) => {
            if (currencySymbol) {
                return `${currencySymbol} ${value}`
            } else {
                return currencyMask.format(value)
            }
        }

        return (
            <>
                {open ? (
                    <Section title={'Débito em folha'}>
                        <CustomButtons onClick={this.props.handleGoBack}>
                            {t('BUTTON_BACK')}
                        </CustomButtons>
                    </Section>
                ) : (
                    <Section title={t('MENU_HISTORY')}>
                        <CustomButtons onClick={() => this.handleGoBack()}>
                            {t('BUTTON_BACK')}
                        </CustomButtons>
                    </Section>
                )}
                <GridBox>
                    <DocumentTitle
                        title={getProgramTitle(
                            open
                                ? 'Débito em folha'
                                : t('NAVPILLS_SHOP_HISTORY'),
                        )}
                    />
                    <AppModal
                        id={this.state?.invoiceById?.id}
                        type={this.state.AppType}
                        open={this.state.open}
                        closeByClick={() => this.handleToggle()}
                        data={invoiceById}
                    />
                    {/*  */}

                    <GridContainer justify="left" style={{ marginTop: '20px' }}>
                        <GridItem xs={12} sm={12} md={3}>
                            <CssTextField
                                id="data"
                                name="data"
                                label={t('PURCHASE_DATE')}
                                value={
                                    moment.utc(invoiceById?.date).format('L') +
                                    ' - ' +
                                    moment(invoiceById?.date).format('HH:mm')
                                }
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                disabled={true}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={9}>
                            <CssTextField
                                id="partner"
                                name="partner"
                                label={t('FIELD_PARTNER')}
                                value={
                                    invoiceById?.partnerName &&
                                    invoiceById?.partnerName
                                        ? invoiceById?.partnerName
                                        : '-'
                                }
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                disabled={true}
                            />
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={12}
                            md={3}
                            style={{ marginTop: '20px' }}
                        >
                            <CssTextField
                                id="invoiceNumber"
                                name="invoiceNumber"
                                label={t('FIELD_INVOICE')}
                                value={
                                    invoiceById.documentID &&
                                    invoiceById.documentID
                                        ? invoiceById.documentID
                                        : '-'
                                }
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                disabled={true}
                            />
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={12}
                            md={3}
                            style={{ marginTop: '20px' }}
                        >
                            <CssTextField
                                id="spentValue"
                                name="spentValue"
                                label={t('PURCHASE_VALUE')}
                                value={
                                    invoiceById?.totalCurrency
                                        ? currencyMask.format(
                                              invoiceById?.totalCurrency,
                                          )
                                        : formatCurrency(
                                              invoiceById?.total ?? 0,
                                          ) || '-'
                                }
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                disabled={true}
                            />
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={12}
                            md={3}
                            style={{ marginTop: '20px' }}
                        >
                            <CssTextField
                                id="freightValue"
                                name="freightValue"
                                label={t('FIELD_FREIGHT_VALUE')}
                                value={
                                    invoiceById.totalShipping
                                        ? formatCurrency(
                                              invoiceById?.totalShipping ?? 0,
                                          )
                                        : ' - '
                                }
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                disabled={true}
                            />
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={12}
                            md={3}
                            style={{ marginTop: '20px' }}
                        />
                        {invoiceById.generated?.find(
                            (item) => item.type.key.toString() === '2',
                        )?.code ? (
                            <GridItem
                                xs={12}
                                sm={12}
                                md={3}
                                style={{ marginTop: '20px' }}
                            >
                                <CssTextField
                                    id="operationID"
                                    name="operationID"
                                    label={t('OPERATION_FIELD')}
                                    value={
                                        invoiceById.generated?.find(
                                            (item) =>
                                                item.type.key.toString() ===
                                                '2',
                                        )?.code
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    disabled={true}
                                />
                            </GridItem>
                        ) : (
                            ''
                        )}
                        <GridItem
                            xs={12}
                            sm={12}
                            md={3}
                            style={{ marginTop: '20px' }}
                        >
                            <CssTextField
                                id="cashbackValue"
                                name="cashbackValue"
                                label={
                                    open
                                        ? 'Cashback acumulado'
                                        : t('ACCUMULATED_CASHBACK')
                                }
                                value={
                                    invoiceById?.generated?.find(
                                        (item) =>
                                            item?.type?.key?.toString() === '2',
                                    )
                                        ? currencyMask.format(
                                              invoiceById?.generated?.find(
                                                  (item) =>
                                                      item?.type?.key?.toString() ===
                                                      '2',
                                              )?.value,
                                          )
                                        : ' - '
                                }
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                disabled={true}
                            />
                        </GridItem>

                        {invoiceById.generated?.find(
                            (item) => item.type.key.toString() === '1',
                        )?.code ? (
                            <GridItem
                                xs={12}
                                sm={12}
                                md={3}
                                style={{ marginTop: '20px' }}
                            >
                                <CssTextField
                                    id="transactionField"
                                    name="transactionField"
                                    label={t('TRANSACTION_FIELD')}
                                    value={
                                        invoiceById.generated?.find(
                                            (item) =>
                                                item.type.key.toString() ===
                                                '1',
                                        )?.code
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    disabled={true}
                                />
                            </GridItem>
                        ) : (
                            ''
                        )}

                        <GridItem
                            xs={12}
                            sm={12}
                            md={3}
                            style={{ marginTop: '20px' }}
                        >
                            <CssTextField
                                id="seed"
                                name="seed"
                                label={
                                    open
                                        ? 'Seeds acumulados'
                                        : 'Total de ' +
                                          localStorage.getItem(
                                              'programCurrencyName',
                                          ) +
                                          ' ' +
                                          t('ACCUMULATED_PREFIX')
                                }
                                value={
                                    (this?.state?.totalPoints === 0 && '-') ||
                                    this?.state?.totalPoints
                                }
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                disabled={true}
                                InputProps={{
                                    startAdornment:
                                        invoiceById?.generated?.find(
                                            (item) =>
                                                item.type.key.toString() ===
                                                '1',
                                        )?.value || invoiceById?.points ? (
                                            <InputAdornment position="start">
                                                {this?.state?.totalPoints &&
                                                this?.props?.generalRulesData
                                                    ?.programCurrencySymbol
                                                    ? this.props
                                                          ?.generalRulesData
                                                          ?.programCurrencySymbol +
                                                      ' '
                                                    : ' '}
                                            </InputAdornment>
                                        ) : (
                                            ''
                                        ),
                                }}
                            />
                        </GridItem>
                        {invoiceById.generated?.find(
                            (item) => item.type.key.toString() === '2',
                        )?.redeemedValue ? (
                            <GridItem
                                xs={12}
                                sm={12}
                                md={3}
                                style={{ marginTop: '20px' }}
                            >
                                <CssTextField
                                    id="redeemedValue"
                                    name="redeemedValue"
                                    label={t('WALLET_REDEEM')}
                                    value={invoiceById.generated
                                        ?.find(
                                            (item) =>
                                                item.type.key.toString() ===
                                                '2',
                                        )
                                        ?.redeemedValue.toLocaleString(
                                            'pt-br',
                                            {
                                                style: 'currency',
                                                currency: 'BRL',
                                            },
                                        )}
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    disabled={true}
                                />
                            </GridItem>
                        ) : (
                            ' '
                        )}
                    </GridContainer>
                    <GridContainer
                        justify="left"
                        style={{ paddingTop: '10px', paddingLeft: '7px' }}
                    >
                        <ModalButton>
                            <span onClick={() => this.handleToggle()}>
                                <i
                                    style={{
                                        margin: '0px 8px',
                                        fontSize: 25,
                                        position: 'relative',
                                        top: '8px',
                                        color: GetThemeColorFromStorage()
                                            ?.navigation?.buttons
                                            ?.backgroundColor,
                                    }}
                                    className={'icon-detalhe_pagamento'}
                                />
                                Detalhes do pagamento
                            </span>
                        </ModalButton>
                    </GridContainer>
                    <GridContainer
                        justify="center"
                        style={{ paddingTop: '-25px', marginBottom: '30px' }}
                    >
                        <GridItem xs={12}>
                            <MuiBox
                                sx={{
                                    padding: '5px 15px',
                                    borderRadius: 1,
                                    backgroundColor: '#F4F4F4',
                                    marginBottom: 1,
                                }}
                            >
                                <MuiTypography
                                    variant="body2"
                                    fontSize={12}
                                    color={
                                        GetThemeColorFromStorage()?.navigation
                                            ?.buttons?.backgroundColor
                                    }
                                    justifyContent="end"
                                    display="flex"
                                >
                                    * O valor de seed acumulado é o total geral
                                    de pontos e não o total por itens
                                </MuiTypography>
                            </MuiBox>
                            <MaterialTable
                                title={false}
                                options={{
                                    search: false,
                                    emptyRowsWhenPaging: false,
                                    tooltip: false,
                                }}
                                columns={[
                                    { title: 'Cód. do item', field: 'code' },
                                    {
                                        title: 'Descrição',
                                        field: 'description',
                                        headerStyle: { textAlign: 'left' },
                                        cellStyle: { textAlign: 'left' },
                                    },
                                    { title: 'Qtd.', field: 'quantity' },
                                    {
                                        title: 'Valor total',
                                        field: 'totalPrice',
                                        headerStyle: { textAlign: 'left' },
                                        cellStyle: { textAlign: 'left' },
                                    },
                                    {
                                        title: this.props.generalRulesData
                                            .programCurrencyName
                                            ? `${this.props.generalRulesData.programCurrencyName} acumulado`
                                            : 'Points',
                                        field: 'points',
                                    },
                                    {
                                        title: 'Cashback acumulado',
                                        field: 'cashbackValue',
                                    },
                                ]}
                                localization={{
                                    pagination: {
                                        firstTooltip: '',
                                        lastTooltip: '',
                                        nextTooltip: '',
                                        previousTooltip: '',
                                        labelRowsSelect: 'Linhas',
                                    },
                                    body: {
                                        emptyDataSourceMessage:
                                            'Não foram encontrados resultados',
                                    },
                                }}
                                data={
                                    invoiceById?.items?.map((item) => ({
                                        code: item?.code,
                                        description: item?.description,
                                        quantity: item?.quantity
                                            ? item?.quantity
                                            : '0',
                                        totalPrice:
                                            item?.paymentForm?.value === 'Money'
                                                ? numberFormatText(
                                                      item?.totalPrice,
                                                      `${this?.props?.generalRulesData?.currencySymbol} `,
                                                      2,
                                                  )
                                                : numberFormatText(
                                                      item?.totalPrice,
                                                      `${this?.props?.generalRulesData?.programCurrencySymbol} `,
                                                      0,
                                                  ),
                                        points: numberFormatText(
                                            item?.points ? item?.points : '0',
                                            this?.props?.generalRulesData
                                                ?.programCurrencySymbol
                                                ? this?.props?.generalRulesData
                                                      ?.programCurrencySymbol +
                                                      ' '
                                                : ' ',
                                            0,
                                            true,
                                        ),
                                        cashbackValue:
                                            item?.cashbackValue === 0
                                                ? '-'
                                                : currencyMask.format(
                                                      item?.cashbackValue,
                                                  ),
                                    })) || []
                                }
                                search={false}
                            />
                        </GridItem>
                    </GridContainer>
                </GridBox>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        invoiceById: state.invoice.invoiceById,
        invoiceByIdFailed: state.invoice.invoiceByIdFailed,
        generalRulesData: state.generalRules.generalRulesData,
    }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        { GetInvoiceById, SweetAlert, HideAlert, ShowLoader, HideLoader },
        dispatch,
    )

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    connectRouterState({
        searchDateFrom: '',
        searchDateTo: '',
        searchPartner: '',
        currentPage: 1,
    }),
    withTranslation(),
    withStyles(shopHistoryStyle),
)(ShopHistoryDetail)
